import React from 'react';
import styled from 'styled-components';
import Container from '../../layout/Container';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Inner = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    text-align: center;
  `}
`;

const Paragraph = styled.p`
  && {
    line-height: 1.7;
    margin: 0;
    max-width: none;
    font-size: ${({ theme }) => theme.font.sizes.lg};
    font-weight: 400;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      line-height: 2;
      font-size: ${({ theme }) => theme.font.sizes.xxl};
    `}
    u {
      text-underline-position: under;
    }
    strong {
      font-weight: 700;
    }
  }
`;

const LandingAltTrailerSection = () => {
  return (
    <Background>
      <Inner>
        <Paragraph>
          Do you struggle with <u>anxiety</u>, <u>sleep issues</u> or{' '}
          <u>balancing your mood</u>?
          <br />
          Do you suffer from a <u>chronic illness</u> or have ongoing{' '}
          <u>health issues</u>?
          <br />
          Would you like to experience the benefits of{' '}
          <strong>meditation</strong>, but don't know{' '}
          <strong>where to start</strong>?
        </Paragraph>
      </Inner>
    </Background>
  );
};

export default LandingAltTrailerSection;
