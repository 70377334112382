import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import GiftSvgPath from '../images/gift.svg';
import { useSiteMetaContext } from '../context/SiteMetaContext';
import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import ScrollTarget from '../components/ScrollTarget';
import TooltipSvgInner from '../components/TooltipSvgInner';
import LandingIntroSection from '../sections/landing/landingIntro';
import RegistrationSection from '../sections/registration';
import StickyRegistration from '../sections/stickyRegistration';
import { MeditationSessionsSection } from '../sections/meditationSessions';
import HomepageAltExpertsSection from '../sections/homepageAltExperts';
import YourHostSection from '../sections/yourHost';
import SponsorsSection from '../sections/sponsors';
import SpeakersCarouselSection from '../sections/speakersCarousel';
import LandingAltTrailerSection from '../sections/landing/landingAltTrailer';
import LandingJoinAlexSection from '../sections/landing/landingJoinAlex';

const RegistrationMobileOnly = styled(RegistrationSection)`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `};
`;

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
`;

const registrationButtonLabel = 'Send Me The Meditations';
const IndexPage = ({ location }) => {
  const { title } = useSiteMetaContext();
  const { allAirtable, landingBg, landingBgMobile } = useStaticQuery(graphql`
    query {
      landingBg: file(relativePath: { eq: "landing_bg_meditation.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landingBgMobile: file(
        relativePath: { eq: "landing_bg_meditation_mobile.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allAirtable(
        filter: {
          table: { eq: "Session" }
          data: {
            BonusSession: { ne: true }
            Speaker: {
              elemMatch: {
                data: { MainHost: { ne: true }, Host: { ne: true } }
              }
            }
          }
        }
      ) {
        edges {
          node {
            data {
              Name
              DateOrder
              BonusSession
              Content {
                childMarkdownRemark {
                  html
                }
              }
              Speaker {
                id
                data {
                  Name
                  WebsiteUri
                  ExpertOrder
                  Host
                  MainHost
                  Avatar {
                    localFiles {
                      childImageSharp {
                        gatsbyImageData(width: 400)
                      }
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);
  // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.affiliate) {
    localStorage.setItem('superconferenceAffiliate', params.affiliate);
  }

  const speakers =
    allAirtable?.edges
      ?.map((edge) => edge?.node)
      .filter(({ data }) => !!data.Speaker)
      .sort(
        (speakerA, speakerB) =>
          speakerA.data.ExpertOrder - speakerB.data.ExpertOrder
      )
      .map(({ data }) => ({
        id: data.Speaker[0].id,
        name: data.Speaker[0].data.Name,
        tagline: data.Name,
        image: data.Speaker[0].data.Avatar.localFiles[0],
        slug: data.Slug,
        content: data.Content,
        category: data.Category,
      })) || [];

  const registrationTooltipInner = (
    <TooltipSvgInner
      svgPath={GiftSvgPath}
      content={
        <>
          Also Includes
          <br />
          Free Ticket
        </>
      }
    />
  );

  return (
    <Layout>
      <Seo title="Home" />

      <LandingIntroSection
        title="10 Free Guided Meditations"
        subtitle={
          <div>
            They are <strong>FREE</strong> and available to{' '}
            <strong>DOWNLOAD NOW</strong>
          </div>
        }
        buttonLabel={registrationButtonLabel}
        tooltipInner={registrationTooltipInner}
        landingBg={landingBg}
        landingBgMobile={landingBgMobile}
        hideVideo
      />

      <RegistrationMobileOnly primaryButtonLabel={registrationButtonLabel} />

      <LandingAltTrailerSection />

      <LandingJoinAlexSection />

      <MeditationSessionsSection />

      <ScrollTarget id="signup" />

      <StickyRegistration
        title={<span>Get 10 free meditations</span>}
        subtitle="Available to download now"
        primaryButtonLabel={registrationButtonLabel}
        tooltipInner={registrationTooltipInner}
      />

      <Background>
        <SpeakersCarouselSection
          speakers={speakers}
          svgPath={GiftSvgPath}
          preTitle={
            <div>
              Your 10 Free Guided Meditations <u>also includes:</u>
            </div>
          }
          title={
            <div>
              FREE TICKET To The Upcoming
              <br />
              {title}
            </div>
          }
          subtitle={
            <div>
              The conference features <span>40+ Speakers</span>, including:
            </div>
          }
        />
        <HomepageAltExpertsSection speakers={speakers} />
        <YourHostSection showHeader />
      </Background>

      <SponsorsSection />
    </Layout>
  );
};

export default IndexPage;
