import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import Container from '../layout/Container';
import Carousel from '../components/Carousel';
import { Speaker } from '../types';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Title = styled.h1`
  font-weight: 700;
  text-align: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  `};
`;
const Subtitle = styled.h2`
  text-align: center;
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
`;
const PositionedCarousel = styled(Carousel)`
  padding-top: ${({ theme }) => theme.sizes.lvl2};
`;

const SpeakerWrapper = styled(Link)`
  color: ${({ theme }) => theme.colours.text};
  text-decoration: none;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`;
const SpeakerAvatarContainer = styled.div`
  border-radius: calc(${({ theme }) => theme.sizes.borderRadius}*2);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  overflow: hidden;

  height: ${({ theme }) => theme.sizes.sizeByFactor(40)};
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;
const SpeakerName = styled.h1`
  font-weight: 700;
`;
const SessionName = styled.div``;

const SizedGiftSvg = styled.img`
  display: block;
  margin: 0 auto ${({ theme }) => theme.sizes.lvl3};
  width: ${({ theme }) => theme.sizes.lvl10};
  height: ${({ theme }) => theme.sizes.lvl10};
`;

interface SpeakerElementProps {
  name: string;
  title: string;
  slug: string;
  image: ImageDataLike;
}
const SpeakerElement = ({ name, title, image, slug }: SpeakerElementProps) => {
  return (
    <SpeakerWrapper to={`/session/${slug}`}>
      <SpeakerAvatarContainer>
        <GatsbyImage
          alt={`Speaker ${name} image`}
          image={getImage(image)}
          objectPosition="top"
        />
      </SpeakerAvatarContainer>
      <SpeakerName className="h4">{name}</SpeakerName>
      <SessionName>{title}</SessionName>
    </SpeakerWrapper>
  );
};

interface SpeakersCarouselSectionProps {
  speakers: Speaker[];
  pretitle?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  svgPath?: string;
}

const SpeakersCarouselSection = ({
  speakers,
  pretitle,
  title,
  subtitle,
  svgPath,
}: SpeakersCarouselSectionProps) => {
  const speakerElements = speakers?.map((speaker) => (
    <SpeakerElement
      name={speaker.name}
      title={speaker.tagline}
      slug={speaker.slug}
      image={speaker.image}
    />
  ));

  return (
    <Background>
      <Container>
        {svgPath && <SizedGiftSvg src={svgPath} />}
        {pretitle && <Subtitle>{pretitle}</Subtitle>}
        {title && <Title className="h2">{title}</Title>}
        {subtitle && <Subtitle className="h4">{subtitle}</Subtitle>}
        <PositionedCarousel
          items={speakerElements}
          numberOfCards={4}
          gutter={32}
          showChevrons
        />
      </Container>
    </Background>
  );
};

export { SpeakersCarouselSection };
export default SpeakersCarouselSection;
