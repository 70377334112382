import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../layout/Container';
import SpeakersList from '../components/SpeakersList';

const Title = styled.h1`
  font-weight: 700;
  text-align: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
    `};
`;

const ArrowContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.lvl3};
`;

const Wrapper = styled(Container)`
  position: relative;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('phoneLarge')`
    padding: ${theme.sizes.lvl5} ${theme.sizes.lvl3};
  `}
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding: ${theme.sizes.lvl7} ${theme.sizes.lvl4};
  `}
  
  ${({ theme }) => theme.breakpoint('desktop')`
    padding: ${theme.sizes.lvl10} ${theme.sizes.lvl14};
  `}
    ${({ theme }) => theme.breakpoint('medium')`
    padding: ${theme.sizes.lvl10} ${theme.sizes.lvl16};
  `}
`;

const HomepageAltExpertsSection = ({ speakers }) => {
  return (
    <Wrapper id="conferenceexperts">
      <Title className="h2">Our Experts Will Cover</Title>
      <ArrowContainer>
        <StaticImage src="../images/arrow_brown.png" width={30} />
      </ArrowContainer>
      <SpeakersList speakers={speakers} hideBuyButton />
    </Wrapper>
  );
};

export default HomepageAltExpertsSection;
